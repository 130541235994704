import classNames from 'classnames'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { InputGroup } from 'shared/InputGroup'
import Box from '../../Box'
import FormFieldError from '../../FormFieldError'
import FormLabel from '../FormLabel/FormLabel'
import styles from './FormField.module.scss'

/**
 * Name: FormField
 * Desc: Render the FormField
 * @param {bool} error
 * @param {bool} isRequired
 * @param {object} errors
 * @param {object} dirties
 * @param {string} name
 * @param {node} customField
 * @param {node} labelSuffix
 * @param {string} fieldLabel
 * @param {string} labelSize
 * @param {bool} isCustomInfoRequired
 * @param {string} labelStyle
 * @param {string} labelDataTest
 *
 */

const FormField = (props) => {
  const {
    showError,
    isRequired,
    errorMessage,
    customField,
    fieldLabel,
    labelSize,
    labelSuffix,
    isCustomInfoRequired,
    labelStyle,
    labelDataTest,
    ...rest
  } = props

  const variantClass = classNames(
    { [styles[labelSize]]: labelSize },
    { [`${labelStyle}`]: labelStyle }
  )
  // TODO: Fix the bleeding props code smell
  const restOmitted = omit(rest, bleedingSpreadedProps)
  const { className: addedClassName } = restOmitted
  const formFieldClasses = classNames(addedClassName, styles.formField)

  return (
    <Box {...restOmitted} className={formFieldClasses}>
      <div className={styles.fieldSection}>
        {fieldLabel ? (
          <FormLabel
            className={variantClass}
            fieldLabel={fieldLabel}
            isCustomInfoRequired={isCustomInfoRequired}
            labelSuffix={labelSuffix}
            id={rest.id}
            data-test={labelDataTest}
          />
        ) : null}
        <div className={styles.requiredWrapper} data-style={rest.name}>
          {customField === null ? (
            <InputGroup {...rest} error={showError} />
          ) : (
            customField
          )}
          {isRequired && <Mandatory />}
        </div>
      </div>
      {showError && errorMessage ? (
        <FormFieldError
          message={errorMessage}
          className={styles.errorTitle}
          dataTest={`error-${rest.name}`}
          id={`error-${rest.name}`}
        />
      ) : null}
    </Box>
  )
}

FormField.defaultProps = {
  customField: null,
  labelSize: '',
  type: 'text',
}

FormField.propTypes = {
  showError: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  customField: PropTypes.node,
  fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelSuffix: PropTypes.node,
  labelSize: PropTypes.string,
  errorMessage: PropTypes.string,
  isCustomInfoRequired: PropTypes.bool,
  labelStyle: PropTypes.string,
  labelDataTest: PropTypes.string,
}
export default memo(FormField)

function Mandatory() {
  return (
    <span className={styles.iconRequired} data-test="mandatory">
      *
    </span>
  )
}

// Values that shouldn't be spread on the component
// TODO: Refactor form field so we dont have to do this in the first place
// Instead of using <FormField everywhere
// It should be transformed in separate components

// <FormLabel
// <FormError

const bleedingSpreadedProps = [
  'inputAriaLabel',
  'labelDataTestDeeper',
  'selectedValue',
  'showLabel',
  'aria-label',
  'borderColor',
  'aria-required',
  'disabled',
  'data-test',
  'id',
  'label',
  'name',
  'name',
  'onChange',
  'onClick',
  'type',
  'value',
  'defaultValue',
  'onInput',
  'readOnly',
  'options',
  'space',
  'variant',
  'maxLength',
  'onKeyPress',
  'aria-describedby',
  'onBlur',
  'onKeyUp',
  'placeholder',
  'required',
  'textRef',
  'checked',
  'dirties',
  'errorIcon',
  'defaultChecked',
  'noContainer',
  'min',
  'prefix',
  'elementRef',
  'dataTest',
  'labelSelectNone',
  'labelClassName',
  'visuallyHidden',
  'notAllowed',
]
