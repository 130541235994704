import { useCallback, useState } from 'react'

export function useLoader(defaultValue) {
  const [state, setState] = useState(defaultValue || LOADER_STATE.IDLE)
  const [error, setError] = useState()

  const setPendingState = useCallback(() => {
    setState(LOADER_STATE.PENDING)
  }, [])

  const setIdleState = useCallback(() => {
    setState(LOADER_STATE.IDLE)
  }, [])

  const setResolvedState = useCallback(() => {
    setState(LOADER_STATE.RESOLVED)
  }, [])

  const setErrorState = useCallback((message) => {
    message && setError(message)
    setState(LOADER_STATE.ERROR)
  }, [])

  const resetError = useCallback(() => {
    setError()
  }, [])

  return {
    isLoading: state === LOADER_STATE.PENDING,
    isResolved: state === LOADER_STATE.RESOLVED,
    isResolvedOrError:
      state === LOADER_STATE.RESOLVED || state === LOADER_STATE.ERROR,
    isError: state === LOADER_STATE.ERROR,
    error,
    setPending: setPendingState,
    setIdle: setIdleState,
    setError: setErrorState,
    setResolved: setResolvedState,
    resetError,
  }
}

export const LOADER_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  ERROR: 'error',
  RESOLVED: 'resolved',
}
