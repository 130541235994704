import classNames from 'classnames'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import styles from './TextArea.module.scss'
import { propsToOmit } from '../FormControlConstants'
/**
 * Name: TextArea
 * Desc: Render text area component
 * @param {string} label
 * @param {string} id
 * @param {string} name
 * @param {string} placeholder
 * @param {string} borderColor
 * @param {string} error
 * @param {object} errors
 * @param {object} dirties
 * @param {string} inputAriaLabel
 */

const TextArea = (props) => {
  const {
    label,
    id,
    name,
    placeholder,
    borderColor,
    error,
    className,
    inputAriaLabel,
    ...rest
  } = props

  const restProps = omit(rest, propsToOmit)
  const classes = classNames(
    styles.formControl,
    { [styles.error]: error },
    { [styles[borderColor]]: borderColor },
    className
  )
  const describedBy = error ? `error-${name}` : null
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        name={name}
        id={id}
        className={classes}
        placeholder={placeholder}
        aria-label={inputAriaLabel || name}
        aria-describedby={describedBy}
        {...restProps}
      />
    </>
  )
}

// Props validation
TextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  borderColor: PropTypes.string,
  error: PropTypes.bool,
  inputAriaLabel: PropTypes.string,
  describedBy: PropTypes.string,
}

export default TextArea
