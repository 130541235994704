import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import Box from '../Box'
import * as colors from 'assets/scss/colors.module.scss'
import styles from './Card.module.scss'
import cx from 'classnames'

/**
 * Card Component that shows a bordered element.
 * Is by default rounded with 3px
 * Reference: https://projects.invisionapp.com/d/main/default/#/console/18221429/396219794/inspect
 * @returns React Element
 */
function Card({
  bordered = true,
  borderColor,
  rounded = '3px',
  showBoxShadowOnHover = true,
  className,
  ...rest
}) {
  const color = COLOR_MAPPING[borderColor] || COLOR_MAPPING.default
  const border = bordered ? `1px solid ${color}` : ''
  const cardClassNames = cx(styles.card, className, {
    [styles.noBoxShadowOnHover]: !showBoxShadowOnHover,
  })
  return (
    <Box
      border={border}
      rounded={rounded}
      {...rest}
      className={cardClassNames}
    />
  )
}

export function splitCardProps(props) {
  const keys = Object.keys(propTypes)
  const cardProps = pick(props, keys)
  const rest = omit(props, keys)
  return { rest, cardProps }
}

const propTypes = {
  bordered: PropTypes.bool,
  rounded: PropTypes.string,
  borderColor: PropTypes.any,
  showBoxShadowOnHover: PropTypes.bool,
  className: PropTypes.string,
  primaryCard: PropTypes.bool,
}
Card.propTypes = propTypes

const COLOR_MAPPING = {
  default: colors.cardBorder,
  green: colors.successColor,
  red: colors.linkColor,
}

export default Card
