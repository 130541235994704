import PropTypes from 'prop-types'
import styles from './Column.module.scss'
import classNames from 'classnames'
import { memo } from 'react'
import Box from '../Box'

/**
 * Name: Column
 * @param {string} className
 * @returns Element
 * @param {node} children
 * @param {string} variant
 */

const Column = ({ className, children = [], variant, ...rest }) => {
  const classes = classNames(
    styles.column,
    styles[className],
    styles[variant],
    rest.className
  )
  return (
    <Box {...rest} className={classes}>
      {children}
    </Box>
  )
}

// PropTypes validation
Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
}

export default memo(Column)
