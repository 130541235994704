export const ALL_PRODUCTS = 'ALL_PRODUCTS'
export const BEST_MATCH = 'BEST_MATCH'
export const CORE_PRODUCTS = 'CORE_PRODUCTS'
export const PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH'
export const PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW'
export const MEDIUM_DESCRIPTION_A_TO_Z = 'MEDIUM_DESCRIPTION_A_TO_Z'
export const MEDIUM_DESCRIPTION_Z_TO_A = 'MEDIUM_DESCRIPTION_Z_TO_A'
export const PRODUCT_NUMBER_A_TO_Z = 'PRODUCT_NUMBER_A_TO_Z'
export const PRODUCT_NUMBER_Z_TO_A = 'PRODUCT_NUMBER_Z_TO_A'
export const MANUFACTURE_A_TO_Z = 'MANUFACTURE_A_TO_Z'
export const MANUFACTURE_Z_TO_A = 'MANUFACTURE_Z_TO_A'
export const SORT_BY_FILTER = 'sortBy'
export const OPTION_BY_FILTER = 'optionsFilter'
export const CATEGORY_FILTER = 'categoriesIds'
export const ALL_PRODUCT_VALUE = 0
export const BEST_MATCH_VALUE = 2
export const TO_FEEDBACK_EMAIL = 'SANationalSearchOptimization@Staples.com'
export const DEFAULT_PAGINATION_NUMBER = 1
export const CLEAR_FILTER_ALL = 'CF_ALL'
export const PAGE_COUNT_KEY = 'page'
export const SORT_BY_KEY = 'sortby'
export const FILTER_BY_KEY = 'filtersby'
export const OPTION_FILTERS_KEY = 'optionsfilter'
export const SORT_KEY = 'sort'
export const KEYWORD_KEY = 'keyWord'

export const REVIEW_COUNT_ZERO = 0

export const PAGE_NUMBER = 'pageNumber'

export const INK_AND_TONER_SELECT_NAME_MAPPINGS = {
  BRAND_NAME: 'InkAndToner-1-Level1',
  MODEL_NAME: 'InkAndToner-1-Level3',
}
