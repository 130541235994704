import PropTypes from 'prop-types'
import { useState } from 'react'
import { ENGLISH } from '~/redux/constants'
import FormField from '../FormControl/FormField'
import {
  getLabelProps,
  getRequiredProps,
} from '../FormControl/FormField/FormFieldUtils'
import messages from './messages'
import { getProvinceOptions } from './ProvinceSelectUtils'

const ProvinceSelect = ({
  language = ENGLISH,
  onChange,
  defaultValue,
  label,
  readOnly,
  disabled,
  defaultProvinceOptions,
  ...rest
}) => {
  const lowerCasedDefault = defaultValue?.toLowerCase()
  const [province, setProvince] = useState(lowerCasedDefault || '')

  const handleSelect = async (e) => {
    const newProvince = options.find(
      (o) => o.value === e.target.value.toLowerCase()
    )
    const { name: longName, value: shortName } = newProvince || {}
    const params = [shortName, longName, e]
    // If the onChange handler exists, wait for the handler to do it's job.
    if (onChange) {
      // defaults to true if there is no "return" from onChange
      // See usage example in Checkout.js
      // Allows to wait for success before updating UI
      const { isSuccess = true } = (await onChange(...params)) || {}
      isSuccess && setProvince(shortName)
    } else {
      // If not just update the state
      setProvince(shortName)
    }
  }
  const requiredProps = getRequiredProps(rest.required)
  const labelFallback = messages[language].provinceLabel
  const labelProps = getLabelProps(label || labelFallback)
  const options = getProvinceOptions(language, defaultProvinceOptions)
  const isDisabled = !!(readOnly || disabled)

  // enables usage in a controlled way, then fall back to internal state
  const value = rest.value?.toLowerCase() || province
  return (
    <FormField
      {...labelProps}
      {...requiredProps}
      selectedValue={province}
      type="select"
      options={options}
      borderColor="primary"
      variant="lg"
      onChange={handleSelect}
      data-test={rest['data-test'] || 'province'}
      disabled={isDisabled}
      className={isDisabled ? 'customSelectDisabledClass' : ''}
      {...rest}
      value={value}
    />
  )
}
// Default Props
ProvinceSelect.defaultProps = {
  language: ENGLISH,
  readOnly: false,
  defaultProvinceOptions: [],
}
ProvinceSelect.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultProvinceOptions: PropTypes.array,
}

export default ProvinceSelect
