import { getCdnUrl, getHostUrl, getUseCdn } from 'services/utils/configUtils'
import { PRODUCT, PROMOTION, THUMBNAIL } from './ProductCardImageConstants'
const HOST_URL = getHostUrl()
const CDN_URL = getCdnUrl()
const USE_CDN = getUseCdn()

export function getImageUrl({
  imageName = '',
  size = '',
  type = PRODUCT,
} = {}) {
  if (!imageName) {
    return ''
  }
  return USE_CDN
    ? getCdnImagePath(type, imageName, size)
    : getOldEwayImageUrl(type, imageName)
}

const getCdnImagePath = (type, imageName, size) => {
  const paths = {
    [THUMBNAIL]: 'products',
    [PRODUCT]: 'products',
    [PROMOTION]: 'promo',
  }
  const sizeParameter = size ? `/${size}x${size}` : ''
  const path = paths[type]
  return `${CDN_URL}/images/${path}/${imageName}${sizeParameter}`
}

export function getOldEwayImageUrl(type = PRODUCT, thumbUrl) {
  const vintageEwayPaths = {
    [THUMBNAIL]: `images/splitImages/thumbnails`,
    [PRODUCT]: `images/splitImages/products`,
    [PROMOTION]: `images/promoImages`,
  }
  const finalThumbnail =
    isProduct(type) || isThumbnail(type) ? getOldEwayImage(thumbUrl) : thumbUrl
  const path = vintageEwayPaths[type]
  return `${HOST_URL}/${path}/${finalThumbnail}`
}

function isProduct(type) {
  return type === PRODUCT
}

function isThumbnail(type) {
  return type === THUMBNAIL
}

/**
 * If you pass "myGreatImage.jpg"
 * The method returns to you "M/myGreatImage.jpg"
 * The first letter is uppercase followed by the rest
 * @param {string} thumbUrl
 */

export function getOldEwayImage(thumbUrl = '') {
  if (!thumbUrl) {
    return thumbUrl
  }
  return `${thumbUrl.charAt(0).toUpperCase()}/${thumbUrl}`
}
