import classNames from 'classnames'
import PropTypes from 'prop-types'
import Box from '../Box'
import IconInfo from '../IconInfo'
import Text from '../Text'
import styles from './FormFieldError.module.scss'

function FormFieldError({
  children,
  message: errorMessage,
  dataTest = '',
  id,
  ...rest
}) {
  const { className } = rest
  const classes = classNames(className, styles.fieldError)
  const message = children || errorMessage
  return message ? (
    <Box className={classes} {...rest}>
      <IconInfo className={styles.icon} />
      <Text
        text={message}
        variant="mdText"
        color="primary"
        data-test={dataTest}
        id={id}
      />
    </Box>
  ) : null
}

FormFieldError.propTypes = {
  message: PropTypes.string,
  children: PropTypes.string,
  as: PropTypes.string,
  dataTest: PropTypes.string,
  id: PropTypes.string,
}

export default FormFieldError
