import PropTypes from 'prop-types'
import styles from './Container.module.scss'
import classNames from 'classnames'
import Box from '../Box'

/**
 * Container component that contains the component and ads padding
 * Mainly used to add a level of padding on elements
 */
const Container = ({ children, className, ...rest }) => {
  const addedStyle = styles[className] || className
  const classes = classNames(styles.container, addedStyle)
  return (
    <Box className={classes} {...rest}>
      {children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Container
