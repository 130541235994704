import classNames from 'classnames'
import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './TextInput.module.scss'
import omit from 'lodash/omit'
import { propsToOmit } from '../FormControlConstants'

/**
 * Name: TextInput
 * Desc: Render TextInput
 * @param {*} label
 * @param {*} icon
 * @param {*} error
 * @param {*} id
 * @param {*} name
 * @param {string} className
 * @param {string} iconDataTest
 * @param {object} errors
 * @param {object} dirties
 * @param {object} textRef
 * @param {*} onIconClick
 * @param {string} iconBg
 * @param {string} borderColor
 * @param {bool} showLabel
 * @param {node} labelSuffix
 * @param {string} buttonAriaLabel
 * @param {string} inputAriaLabel
 */
const TextInput = (props) => {
  const {
    label,
    icon,
    error,
    id,
    name,
    onIconClick,
    className,
    inputClass,
    borderColor,
    iconDataTest,
    textRef,
    showLabel = true,
    labelSuffix,
    dataTest,
    'data-test': properNameDataTest = '',
    buttonAriaLabel,
    inputAriaLabel,
    ...rest
  } = props

  const inputClasses = classNames(styles.formControl, {
    [styles.error]: error,
    [styles[borderColor]]: borderColor,
    [styles[inputClass]]: inputClass,
  })

  const textInputWrapperClasses = classNames(
    className,
    icon ? styles.inputFlex : styles.textInputWrapper,
    className ? styles[className] : ''
  )

  const refObj = textRef ? { ref: textRef } : {}
  const labelClassName = !showLabel ? { className: `${styles.hideLabel}` } : {}
  const restProps = omit(rest, propsToOmit)
  const describedBy = error ? `error-${name}` : null

  return (
    <>
      {label && (
        <label htmlFor={name} {...labelClassName}>
          {label}
          <span> {labelSuffix}</span>
        </label>
      )}

      <div className={textInputWrapperClasses}>
        <input
          name={name}
          id={id}
          data-test={properNameDataTest || dataTest}
          className={inputClasses}
          aria-label={inputAriaLabel || name}
          aria-describedby={describedBy}
          {...restProps}
          {...refObj}
        />
        {icon ? (
          <Button
            role="button"
            icon={icon}
            onClick={onIconClick}
            variant="link"
            data-test={iconDataTest ? `${iconDataTest}-icon` : null}
            iconDataTest={iconDataTest}
            aria-label={buttonAriaLabel}
            className={styles.iconButton}
          />
        ) : null}
      </div>
    </>
  )
}

// Props validation
TextInput.propTypes = {
  onIconClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  iconDataTest: PropTypes.string,
  errors: PropTypes.object,
  dirties: PropTypes.object,
  textRef: PropTypes.object,
  iconBg: PropTypes.string,
  borderColor: PropTypes.string,
  errorIcon: PropTypes.string,
  labelSuffix: PropTypes.node,
  dataTest: PropTypes.string,
  showLabel: PropTypes.bool,
  'data-test': PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  inputAriaLabel: PropTypes.string,
}

export default TextInput
