import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { cloneElement, useContext } from 'react'
import { useProductQuickViewLoader } from './ProductQuickViewUtils'
import { handleProductSegment } from '~/services/telemetaryData/productsTileTelemetary/ProductsTileTelemetary'
import { QUICK_VIEW_SEGMENT_LOCATION } from 'components/shared/SegmentAnalytics/SegmentAnalyticsConstants'
import { AnalyticsContext } from 'shared/Utils/contexts'

const ProductQuickViewDynamic = dynamic(
  () => {
    return import('shared/ProductCard/ProductQuickView')
  },
  // TODO: Find a way to add displayName
  // eslint-disable-next-line react/display-name
  { loading: () => <p>...</p>, ssr: false }
)

ProductQuickViewDynamic.displayName = 'ProductQuickViewDynamic'

export { ProductQuickViewDynamic }

/**
 * Name: ProductQuickView
 * desc: render ProductQuickView component
 * @param {string} language
 * @param {bool} isLoggedIn
 * @param {string} productCode
 * @param {object} settings
 * @param {string} pageName
 * @param {string} productLinkUrl
 * @param {func} addToCartHandler
 * @param {bool} showQuickViewButton
 */
const ProductQuickViewLoader = ({
  language,
  isLoggedIn,
  showQuickViewAddToCart,
  productCode = '',
  settings,
  productLinkUrl = '',
  addToCartHandler,
  quickViewButton = null,
  exportQuickViewToWindow = false,
  product,
  index,
}) => {
  const {
    isQuickViewLoaded,
    productCodeRef,
    showQuickView,
    handleKeyPress,
    openProductQuickView,
    handleQuickViewClose,
  } = useProductQuickViewLoader({ exportQuickViewToWindow })
  const { quickViewAddToCartAnalyticsData = {} } = useContext(AnalyticsContext)

  const handleOnClick = () => {
    handleProductSegment({
      product,
      position: index,
      listId: QUICK_VIEW_SEGMENT_LOCATION,
    })
    openProductQuickView(productCode)
  }

  const cloneQuickViewButton = quickViewButton
    ? cloneElement(quickViewButton, { onClick: handleOnClick })
    : quickViewButton

  return (
    <>
      <AnalyticsContext.Provider
        value={{
          segmentLocation: QUICK_VIEW_SEGMENT_LOCATION,
          index,
          quickViewAddToCartAnalyticsData,
        }}
      >
        {cloneQuickViewButton}
        {isQuickViewLoaded ? (
          <ProductQuickViewDynamic
            language={language}
            isLoggedIn={isLoggedIn}
            showQuickViewAddToCart={showQuickViewAddToCart}
            productCode={productCodeRef.current}
            settings={settings}
            productLinkUrl={productLinkUrl}
            addToCartHandler={addToCartHandler}
            showQuickView={showQuickView}
            onQuickViewClose={handleQuickViewClose}
            onKeyDown={handleKeyPress}
          />
        ) : null}
      </AnalyticsContext.Provider>
    </>
  )
}

// Props Validation
ProductQuickViewLoader.propTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  showQuickViewAddToCart: PropTypes.bool,
  productCode: PropTypes.string,
  settings: PropTypes.object,
  productLinkUrl: PropTypes.string,
  addToCartHandler: PropTypes.func,
  quickViewButton: PropTypes.node,
  exportQuickViewToWindow: PropTypes.bool,
  product: PropTypes.object,
  index: PropTypes.number,
}

export default ProductQuickViewLoader
