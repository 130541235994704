import { getUseProductionSegment } from '~/services/utils/configUtils'
/**
 *
 * @param {String} id unique id to use
 * @param {Object} traits
 */
export function identify(...args) {
  getSegment()?.identify(...args)
}
export function track(...args) {
  getSegment()?.track(...args)
}

function getSegment() {
  return window?.analytics
}

export function getSegmentSrc() {
  const scriptType = getUseProductionSegment() ? 'prod' : 'qa'
  const SEGMENT_SRC = `/static/scripts/segment-${scriptType}.js`
  return SEGMENT_SRC
}
