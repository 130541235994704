import PropTypes from 'prop-types'
import classNames from 'classnames'
import { forwardRef } from 'react'
import styles from './FileInput.module.scss'
import Input from 'components/shared/FormControl/Input'
import { useFileInputContext } from './FileInputContext'
import { FileInputFileName } from './FileInputFileName'
import { isProduction } from '~/config/env/environmentUtils'

export const FileInputTextInput = forwardRef((props, ref) => {
  const { id, placeholderTextRef } = useFileInputContext()
  const { placeholder: placeHolderProp } = props
  const className = classNames(styles.inputPlaceholder, props.className)
  const finalRef = ref || placeholderTextRef
  const placeHolder =
    placeHolderProp || (<FileInputFileName />).type().props.children
  return (
    <Input
      type="text"
      ref={finalRef}
      id={`${id}-filename`}
      autoComplete="off"
      readOnly
      placeholder={placeHolder}
      className={className}
      {...props}
    />
  )
})

FileInputTextInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

if (!isProduction()) {
  FileInputTextInput.displayName = 'FileInputText'
}
