import { track } from '~/components/shared/SegmentAnalytics/SegmentAnalyticsUtils'
import { handlePdPAdobeAnalytics } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsValuesGenerator/PDP/AdobeAnalyticsPdp'
import { sendBloomReachPageAnalytics } from '~/components/shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import isEmpty from 'lodash/isEmpty'
import { camelCaseKeys } from 'services/utils'

export function productDetailsTelemetryActions(productDetailData, details) {
  handlePdPAdobeAnalytics(
    productDetailData.Taxonomy,
    productDetailData.ProductCode
  )
  sendBloomReachPageAnalytics(productDetailData)
  productDetailsSegments(productDetailData, details)
}

function productDetailsSegments(productDetailData, details) {
  const {
    taxonomy = [],
    brand = '',
    availability = '',
    mediaImages = [],
    description = '',
    basePrice = '',
    productCode = '',
    productReview = '',
    estimatedDeliveryTimeMax: maxDeliveryTime = 0,
    estimatedDeliveryTimeMin: minDeliveryTime = 0,
    productCodeJDA: skuCode = '',
    productVariants,
  } = camelCaseKeys(productDetailData)
  const variant = getProductVariant(productVariants, productCode)
  const levels = getProductLevels(taxonomy)
  const category = getProductCategory(taxonomy)
  const result = {
    brand,
    category,
    delivery_postal_code: details?.shippingPostalCode,
    delivery_stock_status: availability,
    image_url: mediaImages[0] ?? '',
    estimated_delivery_time_max: maxDeliveryTime,
    estimated_delivery_time_min: minDeliveryTime,
    name: description,
    price: basePrice,
    product_id: productCode,
    product_rating: productReview.reviewCount,
    sku: skuCode,
    url: window.location.href,
    variant: variant?.description || '',
    ...levels,
  }
  track('Product Viewed', result)
}

function getProductLevels(taxonomy) {
  return taxonomy?.reduce((item, { name = '', id = '' }, index) => {
    const nameKey = `l${++index}_name`
    const handleKey = `l${index}_handle`
    return { ...item, [nameKey]: name, [handleKey]: id }
  }, {})
}

export function getProductCategory(taxonomy) {
  const [category = {}] = taxonomy?.slice(-1)
  return category?.name || ''
}

export function getProductVariant(variants, productCode) {
  if (!variants || isEmpty(variants)) {
    return ''
  }
  const [productVariant] = variants
    .filter(
      (item) =>
        item?.variantType.includes('Colour') ||
        item?.variantType.includes('Couleur')
    )
    .map((items) => {
      const [variantItem = {}] = items?.products?.filter(
        (item) => item?.productCode === productCode
      )
      return variantItem
    })
  return productVariant
}
