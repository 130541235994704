import { getFullLocale } from '~/services/utils/languageUtils'
export function formatPrice(value, language, decimals = 2) {
  const locale = getFullLocale(language)
  const currency = currenciesByLanguage[language]
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: decimals,
  })
    .format(value)
    // on windows it ads the CA need to report to node.
    // it adds a space at the end of the string, we trim the space avoid snapshot failure.
    .replace('CA', '')
    .trimRight()
  return formattedPrice
}

const currenciesByLanguage = {
  en: 'USD',
  fr: 'CAD',
}
