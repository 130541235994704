import PropTypes from 'prop-types'
import Icon from '../Icon'
import classNames from 'classnames'
import styles from './IconInfo.module.scss'

function IconInfo({ className, title = 'iconInfoFilled', ...rest }) {
  const classes = classNames(className, styles.icon)
  return (
    <Icon variant="infoFilled" title={title} className={classes} {...rest} />
  )
}

IconInfo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  props: PropTypes.object,
}
export default IconInfo
