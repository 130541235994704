import classNames from 'classnames'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import Icon from 'shared/Icon'
import Label from 'shared/Label'
import { propsToOmit } from '../FormControlConstants'
import styles from './Select.module.scss'
/**
 * Name: Select
 * Desc: Render select box
 * @param {string} id
 * @param {string} name
 * @param {bool} showLabel
 * @param {string} label
 * @param {func} onChange
 * @param {func} onBlur
 * @param {array} options
 * @param {string} variant
 * @param {string or number} selectedValue
 * @param {string} error
 * @param {object} errors
 * @param {object} dirties
 * @param {string} borderColor
 * @param {node} labelSuffix
 */
const Select = ({
  id,
  name,
  onChange,
  onBlur,
  options,
  label,
  showLabel = true,
  variant,
  disabled,
  selectedValue,
  dataTest,
  error,
  className,
  borderColor,
  labelSuffix,
  defaultValue,
  children,
  ...rest
}) => {
  const errorMessage = ''
  const selectClass = classNames(
    styles.formControl,
    { [styles.error]: error },
    { [styles[borderColor]]: borderColor }
  )
  const wrapperClassName = styles[className] || className
  const selectWrapper = classNames(
    styles.select,
    wrapperClassName,
    { [styles[className]]: className },
    { [styles.lg]: variant === 'lg' },
    { [styles.disabled]: disabled }
  )

  const valueProp = generateValue({
    defaultValue,
    selectedValue: rest.value || selectedValue,
  })

  const labelClassName = !showLabel ? { className: `${styles.hideLabel}` } : {}
  const dataTestValue = rest['data-test'] || dataTest
  const restProps = omit(rest, [...propsToOmit, 'placeholder'])
  const ariaLabel = {
    ...(rest['aria-label'] && { 'aria-label': rest['aria-label'] }),
  }
  const ariaRequired = {
    ...(rest['aria-required'] && { 'aria-required': rest['aria-required'] }),
  }

  const allOptions = options.map((item, index) => (
    <option key={`${item.value}-${index}`} value={item.value}>
      {item.name}
    </option>
  ))
  const describedBy = error ? `error-${name}` : null
  return (
    <>
      {label ? (
        <Label htmlFor={name} {...labelClassName}>
          {label}
          {labelSuffix ? <span> {labelSuffix}</span> : null}
        </Label>
      ) : null}
      <div className={selectWrapper}>
        <select
          id={id}
          name={name}
          {...ariaLabel}
          className={selectClass}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          data-test={dataTestValue}
          {...ariaRequired}
          aria-describedby={describedBy}
          {...valueProp}
          {...restProps}
        >
          {children || allOptions}
        </select>
        {errorMessage ? (
          <div className={styles.errorTitle}>
            <span className={styles.iconDanger}>
              <Icon variant="success" />
            </span>
            {errorMessage}
          </div>
        ) : null}
      </div>
    </>
  )
}

// Default Props
Select.defaultProps = {
  showLabel: false,
  disabled: false,
  options: [],
  selectedValue: '',
  dataTest: '',
  name: '',
  labelSuffix: '',
  onChange() {},
}

// PropTypes validation
Select.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  name: PropTypes.string,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.string,
  dataTest: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  borderColor: PropTypes.string,
  labelSuffix: PropTypes.node,
}

export default Select

const generateValue = function ({ defaultValue, selectedValue }) {
  const nullishCoalescing = [null, undefined]

  if (!nullishCoalescing.includes(defaultValue)) {
    return { defaultValue }
  }

  if (!nullishCoalescing.includes(selectedValue)) {
    return { value: selectedValue }
  }

  return {}
}
