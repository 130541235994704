import { useCallback, useState } from 'react'

export function useToggler(state = false) {
  const [isOpen, setOpen] = useState(Boolean(state))

  const open = useCallback(() => {
    setOpen(true)
  }, [])

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  const toggle = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  return { isOpen, open, close, toggle }
}
