export const RECENTLY_VIEW_SEGMENT_LOCATION = 'recently viewed items'
export const HOME_PAGE_RECOMMENDATION_SEGMENT_LOCATION = 'home_page.rr1'
export const CART_PAGE_RECOMMENDATION_SEGMENT_LOCATION = 'cart_page.rr1'
export const PDP_PAGE_RECOMMENDATION_SEGMENT_LOCATION = 'item_page.rr1'
export const SEARCH_RECOMMENDATION_SEGMENT_LOCATION = 'search_page.rr1'
export const CATEGORY_RECOMMENDATION_SEGMENT_LOCATION = 'category_page.rr1'
export const QUICK_VIEW_SEGMENT_LOCATION = 'quick view'
export const PDP_PAGE_SEGMENT_LOCATION = 'Product'
export const COMPARE_PRODUCTS_SEGMENT_LOCATION = 'compare products'
export const SHOPPING_CART_SEGMENT_LOCATION = 'Shopping Cart'
export const APPROVAL_MODIFICATION_SEGMENT_LOCATION = 'In Approval Modification'

export const SEGMENT_OMITTED_DATA = {
  COMMON: ['cart_id', 'list_id', 'url', 'language_iso_code'],
  CART_PRODUCTS: ['category'],
  SHOPPING_CART: ['image_url'],
  DELETE_PRODUCT: ['language_iso_code'],
}
