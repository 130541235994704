import { useEffect } from 'react'

const handleClickOutside = (event, compRef, outsideClickHandler) => {
  if (!compRef?.current?.contains(event.target)) {
    outsideClickHandler?.(event)
  }
}

const useOutsideClickHandler = (compRef, outsideClickHandler) => {
  useEffect(() => {
    const mousedownHandler = (event) => {
      handleClickOutside(event, compRef, outsideClickHandler)
    }

    document.addEventListener('mousedown', mousedownHandler)
    return () => {
      document.removeEventListener('mousedown', mousedownHandler)
    }
  })
}

export default useOutsideClickHandler
