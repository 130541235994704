import classNames from 'classnames'
import PropTypes from 'prop-types'
import Box from 'shared/Box'
import styles from './Heading.module.scss'
import { resolve } from 'styled-jsx/css'

/**
 * Name: Heading
 * Desc: Render heading
 * @param {string} as
 * @param {string,element} text
 * @param {string} color
 * @param {string,bool} lineThrough
 * @param {string,bool} fontWeightNormal
 * @param {string} fontSize
 * @param {func} onClick
 */

const Heading = ({
  text,
  as: asFromProps = 'h1',
  color,
  lineThrough,
  fontWeightNormal,
  className,
  fontSize,
  children,
  ...rest
}) => {
  const { className: dynamicClassName, styles: dynamicStyles } =
    getDynamicStyles(fontSize, asFromProps)

  const classes = classNames(
    className,
    dynamicClassName,
    styles[asFromProps],
    { [styles[color]]: !!color },
    { [styles.fontWeightNormal]: !!fontWeightNormal },
    { [styles.lineThrough]: !!lineThrough }
  )
  const finalText = children || text
  return (
    <Box as={asFromProps} className={classes} {...rest}>
      {finalText}
      {dynamicStyles}
    </Box>
  )
}

function getDynamicStyles(fontSize, as) {
  return resolve`
    ${as} {
      ${fontSize ? `font-size: ${fontSize};` : ''}
    }
  `
}

// Props Validation
Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  lineThrough: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  fontWeightNormal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fontSize: PropTypes.string,
  children: PropTypes.node,
}

export default Heading
