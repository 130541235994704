import PropTypes from 'prop-types'
import classNames from 'classnames'
import Label from '../../Label'

function FormLabel({
  className: passedClassName,
  fieldLabel,
  isCustomInfoRequired,
  visuallyHidden,
  labelSuffix,
  id,
  htmlFor,
  children,
  'data-test': labelDataTest = '',
}) {
  const className = classNames(passedClassName)
  return (
    <Label
      htmlFor={id || htmlFor}
      className={className}
      visuallyHidden={visuallyHidden}
      data-test={labelDataTest}
    >
      {fieldLabel || children}
      {isCustomInfoRequired ? <span>*</span> : null}
      {labelSuffix ? (
        <span data-style="formTooltip"> {labelSuffix}</span>
      ) : null}
    </Label>
  )
}

FormLabel.propTypes = {
  className: PropTypes.string,
  id: PropTypes.any,
  htmlFor: PropTypes.any,
  children: PropTypes.node,
  fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelSuffix: PropTypes.node,
  isCustomInfoRequired: PropTypes.bool,
  visuallyHidden: PropTypes.bool,
  'data-test': PropTypes.string,
}

export default FormLabel
