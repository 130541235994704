import { KEYS } from '~/components/shared/CustomHooks/useKeyPressEvent'

export const ARROW_DOWN_KEY_CODE = 40
export const SPACE_KEY_CODE = 32
export const ARROW_UP_KEY_CODE = 38
export const ESCAPE_KEY_KEY_CODE = 27
export const ENTER_KEY_CODE = 13

export const defaultCaptureEvents = [
  KEYS.ARROW_DOWN,
  KEYS.ARROW_UP,
  KEYS.ARROW_LEFT,
  KEYS.ARROW_RIGHT,
  KEYS.TAB,
  KEYS.ESCAPE,
]
