import PropTypes from 'prop-types'
import { cloneElement } from 'react'

/**
 * Copies the element from the parent element to the stack
 * Not to be used by its own, refer to the @Stack
 * @param {*} param0
 */

function StackItem({ child, className }) {
  const props = {
    ...child.props,
    className,
  }
  return <>{cloneElement(child, props)}</>
}

StackItem.propTypes = {
  child: PropTypes.node,
  className: PropTypes.string,
}

export default StackItem
