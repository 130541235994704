/* eslint-disable jsx-a11y/no-static-element-interactions */
import omit from 'lodash/omit'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Button from 'shared/Button'
import useOutsideClickHandler from 'shared/CustomHooks/useOutsideClickHandler'
import Heading from 'shared/Heading'
import Portal from 'shared/Portal'
import styles from './Dialog.module.scss'
import { addDialogClass, removeDialogClass } from './DialogUtils'
import TrapFocus from '~/components/shared/TrapFocus'
import AlertNotificationContainer from 'shared/Alerts/AlertNotificationContainer'
import { MODAL_NOTIFICATION_CONTAINER_ID } from './constants'

/**
 *Name: Dialog
 *Desc: show dialog
 * @param {string} id
 * @param {bool} open
 * @param {func} onHide
 * @param {object} children
 * @param {string} variant
 * @param {bool} dialogClose
 * @param {string} className
 * @param {bool} showDialogHeader
 * @param {string} headingText
 * @param {string} closeText
 * @param {bool} showDialogHeaderCloseLink
 *
 */

const Dialog = ({
  id,
  open,
  onHide,
  children,
  variant = 'md',
  showDialogCloseButton = false,
  className,
  closeOnClickOutside = false,
  showDialogHeader,
  headingText = '',
  showDialogHeaderCloseLink = true,
  closeText,
  onKeyDown,
  headingSize = 'h5',
  closeButtonAriaLabel,
  ...rest
}) => {
  useEffect(() => {
    const callback = open ? addDialogClass : removeDialogClass
    callback(id)
    return () => {
      removeDialogClass(id)
    }
  }, [id, open])

  const dialogRef = useRef(null)

  useOutsideClickHandler(dialogRef, () => {
    if (open && closeOnClickOutside) {
      onHide()
    }
  })

  const dialogContainerClasses = classNames(styles.dialog, {
    [styles.show]: open,
  })

  const dialogClasses = classNames(styles.dialogWrapper, {
    [styles[variant]]: variant,
    [styles.fade]: open,
  })
  const dialogContentClasses = classNames(styles.dialogContent, {
    [styles[className]]: className,
  })

  const { ...moreRest } = omit(rest, 'data-test')

  return open ? (
    <Portal selector="body">
      <div
        className={dialogContainerClasses}
        onKeyDown={onKeyDown}
        {...moreRest}
        data-test={`${id}-modal-container`}
      >
        <TrapFocus>
          <div className={dialogClasses}>
            <div className={dialogContentClasses} ref={dialogRef}>
              <AlertNotificationContainer
                containerId={MODAL_NOTIFICATION_CONTAINER_ID}
                maxWidth="auto"
                position="absolute"
              />
              {showDialogCloseButton && (
                <div className={styles.dialogClose}>
                  <Button
                    variant="link"
                    icon="close"
                    onClick={onHide}
                    data-test={`${id}-close-button`}
                    aria-label={closeButtonAriaLabel}
                    role="button"
                  />
                </div>
              )}
              {showDialogHeader ? (
                <div className={styles.dialogHeader}>
                  <Heading role="heading" as={headingSize} text={headingText} />
                  {showDialogHeaderCloseLink ? (
                    <Button
                      variant="link"
                      icon="close"
                      text={closeText}
                      onClick={onHide}
                      data-test={`${id}-close-button`}
                      aria-label={closeButtonAriaLabel}
                      role="button"
                      className={styles.closeButton}
                    />
                  ) : null}
                </div>
              ) : null}

              {children}
            </div>
          </div>
        </TrapFocus>
      </div>
    </Portal>
  ) : null
}

// PropType validation
Dialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.string,
  headingSize: PropTypes.string,
  /* showDialogCloseButton is used for model with close icon*/
  showDialogCloseButton: PropTypes.bool,
  className: PropTypes.string,
  /* showDialogHeader is used for model with heading text and  close text*/
  showDialogHeader: PropTypes.bool,
  headingText: PropTypes.node,
  closeText: PropTypes.string,
  /* showDialogHeaderCloseLink is used for hide close link for model close by default is true*/
  showDialogHeaderCloseLink: PropTypes.bool,
  onKeyDown: PropTypes.func,
  closeButtonAriaLabel: PropTypes.string,
}

export default Dialog
