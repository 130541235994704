import PropTypes from 'prop-types'
import { DialogButton } from '../DialogButton/DialogButton'

function DialogCloseButton({
  action = 'close',
  variant = 'secondary',
  ...rest
}) {
  return <DialogButton action={action} variant={variant} {...rest} />
}

DialogCloseButton.propTypes = {
  action: PropTypes.string,
  variant: PropTypes.string,
}

export { DialogCloseButton }
