import classNames from 'classnames'
import PropTypes from 'prop-types'
import Checkbox from '../FormControl/Checkbox'
import RadioButton from '../FormControl/RadioButton'
import Select from '../FormControl/Select'
import TextArea from '../FormControl/TextArea'
import TextInput from '../FormControl/TextInput'
import { getIsValidTextType } from './InputGroupUtils'
import styles from './InputGroup.module.scss'
import { FileInput } from 'shared/FormControl/FileInput'

/**
 * Name: InputGroup
 * Desc: Render inputs like text, select, checkbox
 * @param {*} props
 * @param {string} labelSize
 */
export const InputGroup = (props) => {
  const { labelSize, 'data-test-label': dataTest, ...rest } = props
  const inputGroupClasses = classNames(styles.formGroup, {
    [styles[labelSize]]: labelSize,
  })
  const { type, showLabel = true, ...moreRest } = rest
  const isValidTextType = getIsValidTextType(type)

  return (
    <div
      data-width="inputWidth"
      className={inputGroupClasses}
      data-input="customInput"
    >
      <>
        {type === 'select' ? (
          <Select {...moreRest} showLabel={showLabel} />
        ) : null}
        {isValidTextType ? (
          <TextInput type={type} {...moreRest} showLabel={showLabel} />
        ) : null}
        {type === 'textarea' ? <TextArea type={type} {...moreRest} /> : null}
        {type === 'checkbox' ? (
          <Checkbox dataTest={dataTest} {...moreRest} />
        ) : null}
        {type === 'file' ? <FileInput {...moreRest} /> : null}
        {type === 'radio' ? <RadioButton {...moreRest} /> : null}
      </>
    </div>
  )
}
// Props validation
InputGroup.propTypes = {
  labelSize: PropTypes.string,
  'data-test-label': PropTypes.string,
}
