import { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Portal extends Component {
  state = { element: null }
  componentDidMount() {
    this.setState({ element: document.querySelector(this.props.selector) })
  }

  render() {
    if (this.state.element === null) {
      return null
    }

    return ReactDOM.createPortal(this.props.children, this.state.element)
  }
}

Portal.propTypes = {
  children: PropTypes.any,
  selector: PropTypes.string,
}
export default Portal
