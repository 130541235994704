import { track } from '~/components/shared/SegmentAnalytics/SegmentAnalyticsUtils'
import {
  getProductCategory,
  getProductVariant,
} from 'services/telemetaryData/productDetailsTelemetary/ProductDetailsTelemetary'
import { camelCaseKeys } from 'services/utils'

export const handleProductSegment = ({
  product,
  position = 1,
  listId,
} = {}) => {
  const {
    brand = '',
    description = '',
    mediaImages = [],
    imageUrl = '',
    productCode = '',
    productVariants,
    taxonomy = [],
    productCodeJDA: skuCode = '',
    unitSellPrice: price,
    combinedBasePrice: regularPrice = '',
  } = camelCaseKeys(product)
  const imagePath = imageUrl || mediaImages?.[0] || ''

  const productTaxonomy = taxonomy ?? []
  const variant = getProductVariant(productVariants, productCode)
  const category = getProductCategory(productTaxonomy)
  track('Product Clicked', {
    brand,
    category: category || '',
    image_url: imagePath,
    list_id: listId,
    name: description,
    position: ++position,
    price,
    product_id: productCode,
    sku: skuCode,
    regular_price: regularPrice,
    url: window.location.href,
    variant: variant?.description || '',
  })
}
