import PropTypes from 'prop-types'
import Button from '../../Button'
import messages from '../messages'
import { ENGLISH } from '~/redux/constants'

const DATA_TESTS = {
  close: 'manage-address-close-button',
  continue: 'manage-address-continue-button',
}
export function DialogButton({
  action,
  'data-test': testAttribute,
  language = ENGLISH,
  ...props
}) {
  const text = messages[language][action]
  const dataTest = testAttribute || DATA_TESTS[action]
  return <Button text={text} data-test={dataTest} {...props} />
}

DialogButton.propTypes = {
  language: PropTypes.string,
  action: PropTypes.string,
  'data-test': PropTypes.string,
  variant: PropTypes.string,
}
