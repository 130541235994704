export function getRequiredProps(isRequired) {
  return {
    ...(isRequired && {
      isCustomInfoRequired: isRequired,
      'aria-required': isRequired,
    }),
  }
}

export function getLabelProps(label) {
  return {
    ...(label && { fieldLabel: label, 'aria-label': label }),
  }
}
