import { isDev } from '~/config/env/environmentUtils'
import { isBrowser } from '~/services/utils/runtimeUtils'
import { EVENT_TYPES, RADIO } from './formUtilsConstants'
import { CHECKBOX, DATE_PICKER, SELECT } from './constants'
export const formUtilsChangeHandler = (event, stateChangeData) => {
  const { target = {}, nativeEvent } = event
  const { values, dirties, valuesRef, dependencies, onValidate, setFormState } =
    stateChangeData
  const eventType = nativeEvent?.type || ''
  const fieldValue = getFieldValue(target)
  if (!event.target.name) {
    if (isDev()) {
      throw new Error(
        `To use please add a "name" property to the input element`
      )
    }
  }
  if (target.name) {
    // Updates the reference value (the form state)
    valuesRef.current[target.name] = fieldValue

    // TODO: Set the value to dirty in impure way (dangerous)
    // Simply update using useState
    dirties[target.name] = true
  }

  const errors = doValidation(values, dependencies, onValidate, eventType)
  setFormState({ errors, dirties })

  return { values: valuesRef.current, errors }
}

export const formUtilsSubmitHandler = (event = {}, stateChangeData = {}) => {
  event.preventDefault?.()
  const {
    values = {},
    onSubmit,
    dependencies,
    setFormState,
    onValidate,
    options = {},
  } = stateChangeData
  const { keepDirtyValuesOnSubmit } = options

  const errors = doValidation(
    values,
    dependencies,
    onValidate,
    EVENT_TYPES.SUBMIT
  )

  const dirties = generateDirtyValues(
    errors,
    keepDirtyValuesOnSubmit,
    stateChangeData.dirties
  )

  setFormState({ errors, dirties })
  const isFormValid = !Object.keys(errors).length

  onSubmit({
    ...stateChangeData,
    errors,
    isFormValid,
  })

  const formAggregatedData = stateChangeData?.dependencies?.formAggregatedData

  // focuses on the field  "automatically" when there's an error
  focusField({ errors, event, formAggregatedData })
  const errorValues = Object.values(errors)

  return {
    values,
    errors,
    errorValues,
    isFormValid,
  }
}

export const focusField = ({ errors, event, formAggregatedData = {} }) => {
  const errorsArray = Object.keys(errors)
  // If no error return to next task
  if (!errorsArray.length) {
    return
  }

  // Get the form elements and iterate to focus first of them (has error)
  const formElements = event?.target?.elements
  if (!formElements) {
    return
  }
  const formElementsArr = [...formElements]
  formElementsArr.find((formElement) => {
    return handleFieldFocus({ formElement, errorsArray, formAggregatedData })
  })
}

const handleFieldFocus = ({ formElement, errorsArray, formAggregatedData }) => {
  const { isFormFocused } = formAggregatedData
  if (isFormFocused) {
    return true
  }

  const noFocusFields = [SELECT, CHECKBOX, RADIO, DATE_PICKER]

  // Skip field that does not have error
  if (!errorsArray.includes(formElement.name)) {
    return false
  }

  formAggregatedData.isFormFocused = true
  // If field is not focusable then scroll to its position in view
  if (
    noFocusFields.includes(formElement.type) ||
    noFocusFields.includes(formElement.nodeName)
  ) {
    formElement.scrollIntoView?.({ block: 'center' })
    if (isBrowser()) {
      window.scrollBy(0, -250)
    }
    return true
  }

  formElement.focus()
  return true
}

const getFieldValue = (target = {}) => {
  const fieldValue = target.type === CHECKBOX ? !!target.checked : target.value
  return fieldValue
}

export const validateNumber = (event) => {
  const charCode = event.charCode || event.keyCode
  const character = String.fromCharCode(charCode)
  if (isNaN(character)) {
    event.preventDefault()
  }
}

export const validateNumberWithDecimalAndComma = (event) => {
  const ASCIICode = event.which ? event.which : event.keyCode
  if (
    ASCIICode !== 44 &&
    ASCIICode !== 46 &&
    ASCIICode > 31 &&
    (ASCIICode < 48 || ASCIICode > 57)
  ) {
    event.preventDefault()
  }
}

export const isValidEmail = (value) => {
  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return value ? mailformat.test(value) : false
}

export const nextTick = (callback) => {
  setTimeout(() => {
    callback()
  }, 0)
}

function generateDirtyValues(
  errors,
  keepDirtyValuesOnSubmit,
  existingDirtyValues
) {
  const dirtyFieldsFromErrors = generateAllValuesTruthy(Object.keys(errors))
  // By default all dirty values are generate from errors on submit unless told otherwise ⬇️
  const dirties = keepDirtyValuesOnSubmit
    ? {
        ...existingDirtyValues,
        ...dirtyFieldsFromErrors,
      }
    : dirtyFieldsFromErrors
  return dirties
}

function doValidation(values, dependencies, onValidate, eventType) {
  const params = { values, dependencies, eventType }
  const returnedErrors = withDefaultEmpty(onValidate)(params)
  return returnedErrors
}

/**
 * Generates all values as truthy
 * @param {*} values - can be an object {hey: 'there'} will become { hey:true }, or an array, ['hey'] will become {hey: true}
 */
export function generateAllValuesTruthy(values = {}) {
  if (Array.isArray(values)) {
    const valueEntries = values.map((e) => {
      return [e, true]
    })
    return Object.fromEntries(valueEntries)
  }
  return Object.keys(values).reduce(
    (acc, value) => setAllValuesToBoolean(acc, value, true),
    {}
  )
}

const setAllValuesToBoolean = (acc, value, boolean) => {
  return { ...acc, [value]: boolean }
}

const withDefaultEmpty = (fn) => {
  return (...args) => {
    return fn?.(...args) || {}
  }
}
