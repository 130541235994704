import PropTypes from 'prop-types'
import styles from './Input.module.scss'
import Box from '~/components/shared/Box'
import omit from 'lodash/omit'
import { propsToOmit } from '../FormControlConstants'
import classNames from 'classnames'
import { forwardRef } from 'react'
import { getIsValidTextType } from '../../InputGroup/InputGroupUtils'
import { isProduction } from '~/config/env/environmentUtils'

/**
 * Name: Input
 * Desc: Render Input
 * @param {*} id
 * @param {*} label
 * @param {*} labelClassName
 */

const Input = forwardRef((props, ref) => {
  const { className, type, ...rest } = props
  const classes = classNames(styles.inputWrapper, className)
  const restProps = omit(rest, propsToOmit)
  const finalType = getIsValidTextType(type) ? type : DEFAULT_TYPE
  return (
    <Box className={classes}>
      <input type={finalType} {...restProps} ref={ref} />
    </Box>
  )
})

if (!isProduction()) {
  Input.displayName = 'Input'
}

const DEFAULT_TYPE = 'text'
Input.defaultProps = {
  type: DEFAULT_TYPE,
}
// Props validation
Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
}

export default Input
