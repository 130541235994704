import { ENGLISH, FRENCH } from '~/redux/constants'
import messages from './messages'
import { provinces } from './ProvinceSelectConstants'
export const getProvinceOptions = (
  language = ENGLISH,
  defaultProvinceOptions
) => {
  const formattedProvinces = provinces.map((element) => ({
    ...element,
    name: messages[language][element.value],
  }))

  const defaults = {
    value: '',
    name: messages[language].selectAProvince,
  }

  return defaultProvinceOptions.length > 0
    ? [defaults, ...defaultProvinceOptions]
    : [defaults, ...formattedProvinces]
}

export const getProvinceValueByName = function (value) {
  const uppercaseValue = String(value).toUpperCase()
  const lowercaseValue = String(value).toLowerCase()
  const [{ value: provinceValue = '' } = {}] = provinces.filter(
    ({ value, name }) => {
      const enProvince = String(messages[ENGLISH]).toUpperCase()
      const frProvince = String(messages[FRENCH]).toUpperCase()
      return (
        lowercaseValue === value ||
        lowercaseValue === name.toLowerCase() ||
        [enProvince, frProvince].includes(uppercaseValue)
      )
    }
  )
  return provinceValue
}
