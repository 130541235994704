import {
  sendTrackingInformation,
  updateAnalyticsValuesWith,
} from '../../AdobeAnalyticsUtils'

export function handlePdPAdobeAnalytics(values, productCode) {
  const taxonomies = formatTaxonomies(values)
  const [firstTaxonomy] = taxonomies
  const joined = taxonomies.join(':')

  const title = `ProductDetail:${joined}`
  updateAnalyticsValuesWith({
    pageName: title,
    prop5: firstTaxonomy,
    prop6: joined,
    products: `;${productCode}`,
    prop12: title,
    prop26: title,
    events: 'event3,prodView',
  })
  sendTrackingInformation()
}

function formatTaxonomies(values = []) {
  return values?.map((v) => v.Name)
}
