import classNames from 'classnames'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import { memo } from 'react'
import styles from './Row.module.scss'
import Box from '../Box'

/**
 * Name: Row
 * @param {string} className
 * @param {string} variant
 * @param {node} children
 * @returns Element
 */

const Row = ({ className, variant, children = [], ...rest }) => {
  const rowClass = styles[className] || ''
  const variantClass = variant ? styles[variant] : ''

  const uniques = uniq([styles.row, variantClass, className, rowClass])
  const rowClasses = classNames(uniques)
  return (
    <Box {...rest} className={rowClasses}>
      {children}
    </Box>
  )
}

// PropTypes validation
Row.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
}

export default memo(Row)
