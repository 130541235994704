import classNames from 'classnames'
import PropTypes from 'prop-types'
import Box from '../Box'
import styles from './Label.module.scss'

const Label = ({ children, className, visuallyHidden = false, ...rest }) => {
  const classes = classNames(className, {
    [styles.visuallyHidden]: !!visuallyHidden,
  })
  return (
    <Box as="label" className={classes} {...rest}>
      {children}
    </Box>
  )
}

Label.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  visuallyHidden: PropTypes.bool,
}

export default Label
