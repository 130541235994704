import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
const SimpleBar = dynamic(() => import('simplebar-react'), {
  ssr: false,
})

/**
 * Name: ScrollBar
 * Desc: Render ScrollBar component
 * @param {element} children
 * @returns Element
 */

const ScrollBar = ({ children, ...rest }) => {
  return <SimpleBar {...rest}>{children}</SimpleBar>
}

// Props Validation
ScrollBar.propTypes = {
  children: PropTypes.node,
  autoHide: PropTypes.bool,
}

ScrollBar.defaultProps = {
  autoHide: true,
}

export default ScrollBar
