/**
 * Hack to allow using the box component in <FormField
 */
export const propsToOmit = [
  'errorIcon',
  'error',
  'minH',
  'borderColor',
  'inputAriaLabel',
  'notAllowed',
]
