import { useCallback, useEffect, useRef, useState } from 'react'
import { fetchProductDetails } from '~/services/productServices'
import { isEscPress } from '~/services/utils'
import { PRODUCT_QUICK_VIEW } from '../../Dialog/constants'
import { removeDialogClass } from '../../Dialog/DialogUtils'

export const setProductQuickViewData = async ({
  productDetailData,
  language,
  resetProductDetail,
}) => {
  if (productDetailData.ProductCode) {
    const productDetail = await fetchProductDetails(
      language,
      productDetailData.ProductCode
    )
    resetProductDetail(productDetail)
    return productDetail
  }
}

export function useProductQuickViewLoader({
  exportQuickViewToWindow = false,
} = {}) {
  const [isQuickViewLoaded, setIsQuickViewLoaded] = useState(false)
  const [showQuickView, setShowQuickView] = useState(true)
  const productCodeRef = useRef()

  const openProductQuickView = useCallback(
    (productCode) => {
      if (!productCode) {
        return
      }
      productCodeRef.current = productCode
      setIsQuickViewLoaded(true)
      isQuickViewLoaded && setShowQuickView(true)
    },
    [isQuickViewLoaded]
  )

  useEffect(() => {
    if (exportQuickViewToWindow) {
      window.openProductQuickView = openProductQuickView
    }
  }, [openProductQuickView, exportQuickViewToWindow])

  const handleQuickViewClose = () => {
    setShowQuickView(false)
    removeDialogClass(PRODUCT_QUICK_VIEW)
  }
  const handleKeyPress = (e) => {
    if (isEscPress(e)) {
      handleQuickViewClose()
    }
  }

  return {
    isQuickViewLoaded,
    productCodeRef,
    showQuickView,
    handleKeyPress,
    openProductQuickView,
    handleQuickViewClose,
  }
}
