import Box from 'components/shared/Box'
import PropTypes from 'prop-types'
import { useFileInputContext } from './FileInputContext'

export function FileInputFileName({ children, ...rest } = {}) {
  const { fileName } = useFileInputContext()
  return <Box {...rest}>{children || fileName}</Box>
}

FileInputFileName.propTypes = {
  children: PropTypes.node,
}
