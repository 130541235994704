import PropTypes from 'prop-types'
import { DialogButton } from '../DialogButton/DialogButton'

function DialogConfirmButton({
  action = 'continue',
  variant = 'primary',
  ...rest
}) {
  return <DialogButton action={action} variant={variant} {...rest} />
}

DialogConfirmButton.propTypes = {
  action: PropTypes.string,
  variant: PropTypes.string,
}

export { DialogConfirmButton }
