import PropTypes from 'prop-types'
import Box from '../Box'
import { forwardRef } from 'react'

/**
 * Displays a Flex container
 * @param {*} param0
 */

const Flex = forwardRef(({ children, ...rest }, ref) => {
  return (
    <Box {...rest} display="flex" ref={ref}>
      {children}
    </Box>
  )
})

Flex.displayName = 'Flex'

Flex.propTypes = {
  children: PropTypes.any,
}
export default Flex
