export const provinces = [
  { value: 'ab', name: 'ALBERTA' },
  { value: 'bc', name: 'BRITISH COLUMBIA' },
  { value: 'mb', name: 'MANITOBA' },
  { value: 'nb', name: 'NEW BRUNSWICK' },
  { value: 'nl', name: 'NEWFOUNDLAND AND LABRADOR' },
  { value: 'nt', name: 'NORTHWEST TERRITORIES' },
  { value: 'nu', name: 'NUNAVUT' },
  { value: 'ns', name: 'NOVA SCOTIA' },
  { value: 'on', name: 'ONTARIO' },
  { value: 'pe', name: 'PRINCE EDWARD ISLAND' },
  { value: 'qc', name: 'QUEBEC' },
  { value: 'sk', name: 'SASKATCHEWAN' },
  { value: 'yt', name: 'YUKON' },
]
