export const EVENT_TYPES = {
  BLUR: 'blur',
  INPUT: 'input',
  SUBMIT: 'submit',
}

export const DEFAULT_SUBMIT_OPTIONS = {
  emptyDirtyFieldsOnSubmit: true,
}

export const RADIO = 'radio'
