import PropTypes from 'prop-types'
import { memo } from 'react'
import Heading from 'shared/Heading'
import Box from '../Box'
import styles from './SectionTitle.module.scss'
import classNames from 'classnames'

/**
 * Name: SectionTitle
 * Desc: render top section heading
 * @param {string} title
 * @param {string} as
 * @param {node} children
 */
const SectionTitle = ({
  title,
  as,
  children,
  className,
  showChildren = true,
  ...rest
}) => {
  const classes = classNames(styles.sectionTitle, className)
  return (
    <Box
      className={classes}
      display="flex"
      align="center"
      justify="space-between"
      flexWrap="flex-wrap"
      mb="15px"
      p="10px 12px"
      bg="#f3f3f3"
      {...rest}
    >
      {title && <Heading as={as} text={title} className={styles.title} />}
      {showChildren ? children : null}
    </Box>
  )
}

SectionTitle.defaultProps = {
  as: 'h2',
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  showChildren: PropTypes.bool,
  children: PropTypes.node,
}

export default memo(SectionTitle)
