export default {
  en: {
    close: 'Close',
    continue: 'Continue',
  },
  fr: {
    close: 'Fermer',
    continue: 'Continuer',
  },
}
