import { ENGLISH } from '@redux/constants'
import PropTypes from 'prop-types'
import { formatPrice } from './PriceUtils'

const Price = ({
  children,
  language = ENGLISH,
  decimals = 2,
  as: Component,
  ...rest
}) => {
  const formattedPrice = formatPrice(children, language, decimals)
  return (
    isNumeric(children) && <Component {...rest}>{formattedPrice}</Component>
  )
}

// Default Props
Price.defaultProps = {
  decimals: 2,
  as: 'span',
  language: ENGLISH,
}

function isNumeric(num) {
  return !isNaN(num)
}

export default Price

Price.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]),
  children: PropTypes.node,
  decimals: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
}
