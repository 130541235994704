import { ENGLISH, FRENCH } from '@redux/constants'
import { isProduction } from '~/config/env/environmentUtils'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useRef, useState } from 'react'
import {
  pressEnter,
  pressSpace,
} from 'services/utils/keyboardUtils/keyboardUtils'
import { FileInputProvider } from './FileInputContext'
import { FileInputHiddenInput } from './FileInputHiddenInput'
import messages from './messages'
/**
 * Accessible file input component
 * Based on https://accessabilly.com/a-styled-accessible-file-upload/
 */
const FileInput = forwardRef(
  (
    {
      id = 'file-input',
      name,
      onKeyDown,
      onChange,
      onBlur,
      disabled,
      isLoading,
      language = ENGLISH,
      placeHolder: placeHolderFromProps,
      text = '',
      'data-test': dataTest,
      children,
    },
    ref
  ) => {
    const fileInputText = text || messages[language].chooseFile
    const translations = messages[language]
    const fileInputRef = useRef(ref)
    const [isFocus, setIsFocus] = useState()
    const placeholderTextRef = useRef()
    const [fileName, setFileName] = useState(
      placeHolderFromProps || translations.initialValue
    )
    const isInitialValue = fileName === translations.initialValue
    const fileNameRef = useRef(fileName)

    useEffect(() => {
      const isInitialValue =
        fileNameRef.current === messages[ENGLISH].initialValue ||
        fileNameRef.current === messages[FRENCH].initialValue
      if (isInitialValue) {
        setFileName(messages[language].initialValue)
      }
    }, [language])

    function handleKeyDown(e) {
      e.preventDefault()
      if (pressSpace(e) || pressEnter(e)) {
        fileInputRef.current.click()
      }
      onKeyDown?.(e)
    }

    function handleClick(e) {
      e.preventDefault()
      fileInputRef.current?.focus()
      fileInputRef.current?.blur()
      fileInputRef.current?.click()
    }

    function handleOnChange(e = {}) {
      const { files = [] } = e.target || {}
      const fileExists = !!files.length
      if (fileExists) {
        const file = files[0]
        setFileName(file.name)
        onChange?.(e, file)
        placeholderTextRef.current?.focus()
      } else {
        setFileName(translations.initialValue)
        onChange?.(e)
      }
    }

    function handleOnBlur(e = {}) {
      setIsFocus(false)
      onBlur?.(e)
    }

    return (
      <FileInputProvider
        value={{
          name,
          isFocus,
          dataTest,
          fileInputText,
          fileInputRef,
          isInitialValue,
          placeholderTextRef,
          fileName,
          disabled,
          isLoading,
          setIsFocus,
          handleClick,
          handleOnBlur,
          handleOnChange,
          handleKeyDown,
          id,
        }}
      >
        {children}
        <FileInputHiddenInput ref={fileInputRef} />
      </FileInputProvider>
    )
  }
)

if (!isProduction()) {
  FileInput.displayName = 'FileInput'
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  placeHolder: PropTypes.string,
  'data-test': PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
}

export { FileInput }
