import PropTypes from 'prop-types'
import Text from 'shared/Text'
import styles from './InfoLabel.module.scss'
import classNames from 'classnames'

/**
 * Name: InfoLabel
 * Desc: Render info label
 * @param {string} text
 * @param {string} variant
 * @param {string} color
 * @param {string} strong
 * @param {string} variantText
 * @param {node} children
 */
const InfoLabel = ({
  text,
  variant,
  color,
  strong,
  variantText,
  children,
  className,
  ...rest
}) => {
  const classes = classNames(styles[variant], styles.infoLabel, className)
  return (
    <div data-product-footer="hidden" className={classes}>
      <Text
        text={text}
        color={color}
        fontWeight={strong}
        variant={variantText}
        {...rest}
      >
        {children}
      </Text>
    </div>
  )
}

// Default Props
InfoLabel.defaultProps = {
  variant: 'primary',
  color: 'white',
  strong: '',
  variantText: 'xsText',
}

// Props Validation
InfoLabel.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  strong: PropTypes.string,
  variantText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default InfoLabel
