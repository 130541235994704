import cx from 'classnames'
import Flex from 'components/shared/Flex'
import { isDev } from '~/config/env/environmentUtils'
import PropTypes from 'prop-types'
import { Children } from 'react'
import { resolve } from 'styled-jsx/css'
import StackItem from './StackItem/StackItem'

/**
 * Allows you to add space between elements
 * @param {string} spacing the spacing needed
 *
 * <Stack spacing="10px">
 *  <div>1</div>
 *  <div>3</div>
 *  <div>3</div>
 * </Stack>
 * Will add a spacing of 10 pixels between all elements.
 *
 */
function Stack({ spacing, children, ...rest }) {
  const { className: dynamicClassName, styles: dynamicStyles } =
    getStackItemDynamicStyles(spacing)

  return (
    <Flex {...rest}>
      {Children.map(children, (child, index) => {
        const { type, props = {} } = child || {}
        const { className: childClassName } = props
        const isLastElement = children.length - 1 === index
        const showStackItem = !isLastElement && spacing && type

        // Ads the dynamic spacing for element element except the last
        const allClasses = cx(childClassName, dynamicClassName)
        return showStackItem ? (
          <StackItem child={child} className={allClasses} />
        ) : (
          child
        )
      })}
      {dynamicStyles}
    </Flex>
  )
}

if (isDev()) {
  Stack.displayName = 'Stack'
}

Stack.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.string,
}

function getStackItemDynamicStyles(spacing) {
  return resolve`
  * {
    margin-right: ${spacing};
  }
`
}
export default Stack
