export default {
  en: {
    provinceLabel: 'Province',
    selectAProvince: '-- Select a province --',
    ab: 'Alberta',
    bc: 'British Columbia',
    mb: 'Manitoba',
    nb: 'New Brunswick',
    nl: 'Newfoundland And Labrador',
    nt: 'Northwest Territories',
    nu: 'Nunavut',
    ns: 'Nova Scotia',
    on: 'Ontario',
    pe: 'Prince Edward Island',
    qc: 'Quebec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
  fr: {
    provinceLabel: 'Province',
    selectAProvince: '-- Sélectionnez une province --',
    ab: 'Alberta',
    bc: 'Colombie-Britannique',
    mb: 'Manitoba',
    nb: 'Nouveau Brunswick',
    nl: 'Terre-Neuve et Labrador',
    nt: 'Territoires du Nord-Ouest',
    nu: 'Nunavut',
    ns: 'Nouvelle-Écosse',
    on: 'Ontario',
    pe: 'Île-du-Prince-Édouard',
    qc: 'Québec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
}
