import PropTypes from 'prop-types'
import styles from './FileInput.module.scss'
import { isProduction } from '~/config/env/environmentUtils'
import { useFileInputContext } from './FileInputContext'
import { forwardRef } from 'react'

export const FileInputHiddenInput = forwardRef(
  ({ 'data-test': dataTestFromProps = '', text: textFromProps = '' }, ref) => {
    const {
      dataTest: dataTextContext,
      id,
      handleOnChange,
      handleOnBlur,
      fileInputText,
      setIsFocus,
      name,
    } = useFileInputContext()

    function handleOnFocus() {
      setIsFocus(true)
    }

    const dataTest =
      dataTestFromProps || dataTextContext || `${id}-file-uploader-input`
    const text = textFromProps || fileInputText
    const htmlFor = `${id}-filename`
    return (
      <>
        <input
          type="file"
          id={id}
          data-test={dataTest}
          onFocus={handleOnFocus}
          name={name}
          ref={ref}
          className={styles.visuallyHidden}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <label htmlFor={htmlFor} className={styles.visuallyHidden}>
          {text}
        </label>
      </>
    )
  }
)

if (!isProduction()) {
  FileInputHiddenInput.displayName = 'FileInputHiddenInput'
}

FileInputHiddenInput.propTypes = {
  'data-test': PropTypes.string,
  text: PropTypes.string,
  translations: PropTypes.shape({
    chooseFile: PropTypes.any,
  }),
}
