import PropTypes from 'prop-types'
import { memo } from 'react'
import styles from './Image.module.scss'
import classNames from 'classnames'

/**
 *Name: Image Element
 *Desc: Renders an image, simply.
 * @param {string} src
 * @param {string} alt
 * @param {string} title
 * @param {string} target
 * @param {string} link
 * @param {string} defaultImage
 * @param {string} className
 * @returns Element
 */

const Image = ({
  onClick,
  src,
  alt,
  title,
  link,
  target,
  fluid,
  defaultImage,
  className,
  tabIndex,
  'data-test': dataTest,
  cursor = false,
  ...rest
}) => {
  const imageClasses = classNames(className, {
    [styles.w100]: fluid,
    [styles.pointer]: cursor === 'pointer',
  })
  const finalClassNameImage = imageClasses === '' ? null : imageClasses

  const handleImageError = (event) => {
    event.target.src = defaultImage
  }

  const img = (
    <img
      src={src}
      alt={alt}
      title={title}
      loading="lazy"
      className={finalClassNameImage}
      onError={defaultImage ? handleImageError : null}
      data-test={dataTest}
      {...rest}
    />
  )
  if (link || onClick) {
    return (
      <a
        onClick={onClick}
        href={link}
        target={target}
        className={styles.linkStyling}
        tabIndex={tabIndex || '0'} //ToDo - Need to add focus on current focus image anchor tag & also move the anchor tag to parent component & wrap the Image component in it
      >
        {img}
      </a>
    )
  }
  return img
}

Image.defaultProps = {
  alt: null,
  target: null,
  onClick: null,
  fluid: false,
  defaultImage: null,
}

// Props Validation
Image.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  'data-test': PropTypes.string,
  cursor: PropTypes.string,
  defaultImage: PropTypes.string,
  /**
   * If you want the image to take full with use fluid
   * Adds a width of 100% to the image
   */
  fluid: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
}

export default memo(Image)
