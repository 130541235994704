import PropTypes from 'prop-types'
import styles from './RadioButton.module.scss'
import omit from 'lodash/omit'

/**
 * Name: RadioButton
 * Desc: Render RadioButton
 * @param {*} id
 * @param {*} label
 * @param {*} labelClassName
 */

const RadioButton = ({ elementRef, labelClassName, ...props }) => {
  const { radioButton } = styles
  const { id, errors, name } = props
  const restProps = omit(props, ['errorIcon', 'fieldMsgIcon', 'error', 'label'])
  const describedBy = errors ? `error-${name}` : null
  return (
    <div className={radioButton}>
      <input
        type="radio"
        id={id}
        {...restProps}
        ref={elementRef}
        aria-describedby={describedBy}
      />
      <label htmlFor={props.id} className={labelClassName}>
        {props.label}
      </label>
    </div>
  )
}

// Props validation
RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  elementRef: PropTypes.object,
  labelClassName: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  name: PropTypes.string,
}

export default RadioButton
