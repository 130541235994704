import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'

const OrderContext = createContext({})
const { Provider } = OrderContext

export function OrderContextProvider({ children, order = {} }) {
  const { orderProducts = [] } = order
  const hasCartProducts = !!orderProducts.length
  const mergedOrder = {
    ...order,
    hasCartProducts,
  }
  return <Provider value={mergedOrder}>{children}</Provider>
}

OrderContextProvider.propTypes = {
  children: PropTypes.node,
  order: PropTypes.object,
}

export function useOrderContext() {
  return useContext(OrderContext)
}

export default OrderContext
