import { updateBuyerLanguage } from 'api'
import { COOKIES } from 'services/constants'
import { setCookie } from 'services/cookieManager'
import { getOldEwayFullLocale } from 'services/utils/languageUtils'
import { updateAndSendTrackingWith } from 'shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { changeLanguage } from './actions'
import { getOppositeLanguageUrl } from 'shared/EwayRouter/EwayRouterUtils'
import { windowReload } from '~/services/utils/windowUtils'

/**
 * Side Effect that sets the language
 *
 * TODO: Move elsewhere, I should be able to do top level import but causes self reference
 * Errors in app
 * @param {*} buyerId
 * @param {*} selectedLanguage
 */
export function setLanguage(...args) {
  const [, selectedLanguage] = args
  return function (dispatch) {
    const replaceUrl = getOppositeLanguageUrl(window.location.href)

    dispatch(changeLanguage(selectedLanguage))
    changeBrowserLanguage(replaceUrl, selectedLanguage)

    handleLanguageChangeSideEffects(...args)
  }
}

function changeBrowserLanguage(replaceUrl, language) {
  window.history.pushState('', '', replaceUrl)
  document.documentElement.lang = language
}

function handleLanguageChangeSideEffects(buyerId, language, options = {}) {
  updateAndSendTrackingWith({
    eVar35: getOldEwayFullLocale(language),
    prop19: getOldEwayFullLocale(language),
  })

  setCookie(COOKIES.LANGUAGE, `${language}-CA`)

  if (buyerId) {
    return updateBuyerLanguage(buyerId, language)
  }
  if (options.reloadPage) {
    windowReload()
  }
}
