const MODAL_OPEN = 'modal-open'

export const isDialogOpen = (id) => {
  let isOpen = false
  if (process.browser && document?.body) {
    isOpen =
      document.body.classList.contains(MODAL_OPEN) &&
      document.body.classList.contains(id)
  }
  return isOpen
}

export const addDialogClass = (id, className = MODAL_OPEN) => {
  if (process.browser && document?.body) {
    document.body.classList.add(className, id)
  }
}

export const removeDialogClass = (id, className = MODAL_OPEN) => {
  if (process.browser && document?.body) {
    if (isDialogOpen(id)) {
      document.body.classList.remove(className, id)
    }
  }
}
