import { bindActionCreators } from 'redux'
import { setLanguage } from '@redux/effects'
import { connect } from 'react-redux'

export function languageConnector(WrappedComponent, options) {
  function mapStateToProps(state) {
    const language = state.state?.session?.language || state.state.language
    return {
      language,
    }
  }
  const setLanguageWithOptions = withOptions(setLanguage)

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLanguage: setLanguageWithOptions }, dispatch)
  }

  function withOptions(fn) {
    return (...args) => {
      return fn(...args, options)
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
