import { KEYS } from '~/components/shared/CustomHooks/useKeyPressEvent'
import {
  ARROW_DOWN_KEY_CODE,
  ARROW_UP_KEY_CODE,
  ENTER_KEY_CODE,
  ESCAPE_KEY_KEY_CODE,
  SPACE_KEY_CODE,
} from '~/services/utils/keyboardUtils/keyboardCodeConstants'
export function pressUp(e = {}) {
  return e.keyCode === ARROW_UP_KEY_CODE || e.key === KEYS.ARROW_UP
}

export function pressSpace(e = {}) {
  return e.keyCode === SPACE_KEY_CODE
}

export function pressDown(e = {}) {
  return e.keyCode === ARROW_DOWN_KEY_CODE || e.key === KEYS.ARROW_DOWN
}

export function pressEscape(e = {}) {
  return e.keyCode === ESCAPE_KEY_KEY_CODE || e.key === KEYS.ESCAPE
}

export function pressEnter(e = {}) {
  return e.keyCode === ENTER_KEY_CODE || e.key === KEYS.ENTER
}
