import { REVIEW_COUNT_ZERO } from 'components/containers/BrowseProducts/constants'
import { isEmptyArray } from 'services/utils'
import { getHostUrl } from 'services/utils/configUtils'
import { getProductDetails } from '~/api/productOperations'
import messages from 'components/containers/ProductDetail/ProductDetailPrimarySection/ProductDetailContainer/ProductDetailSectionTop/messages'
import { THUMBNAIL } from '~/components/shared/ProductCard/ProductCardImage/ProductCardImageConstants'
import { getImageUrl } from '~/components/shared/ProductCard/ProductCardImage/ProductCardImageUtils'
import { getFeeToDisplay } from './fees/products/feeProductsAdditionalFees'
const HOST_URL = getHostUrl()

export async function fetchProductDetails(
  language,
  productCode,
  showProductAccessories = true,
  productReviewsCanRead,
  productReviewsCanWrite
) {
  const productDetailAPIData = await getProductDetails(language, productCode)
  productDetailAPIData.accessoriesProduct = []
  if (productDetailAPIData?.Accessories) {
    const {
      Accessories: { RecommendedItems = [], MandatoryItems = [] },
    } = productDetailAPIData
    if (showProductAccessories && (RecommendedItems || MandatoryItems)) {
      Object.assign(
        productDetailAPIData.accessoriesProduct,
        RecommendedItems,
        MandatoryItems
      )
    }
  }

  if (
    !productDetailAPIData.RelatedItems ||
    (productDetailAPIData.RelatedItems &&
      !productDetailAPIData.RelatedItems.length)
  ) {
    productDetailAPIData.RelatedItems = []
  }

  const productDetails = { ...productDetailAPIData }
  if (productDetails?.Taxonomy && !isEmptyArray(productDetails.Taxonomy)) {
    productDetails.Taxonomy.forEach((data) => {
      data.title = data.Name
      data.link = `${HOST_URL}/${language}/Shopping/Search/Category/${data.Id}?categoryDescription=${data.Name}&filter=0`
    })
  }
  if (productDetails?.ImageNames?.length) {
    productDetails.ImageNames = productDetails.ImageNames?.map?.(
      (imageName) => {
        if (imageName?.length) {
          const thumbnail = getImageUrl({
            imageName,
            type: THUMBNAIL,
          })
          const main = getImageUrl({ imageName, size: '600' })
          return {
            imgUrl: thumbnail,
            imgMainUrl: main,
            title: imageName,
          }
        }
      }
    )
  }
  if (productDetails?.ProductFamily?.length) {
    productDetails.ProductFamily.forEach((data) => {
      data.value = data.ProductCode
      data.name = data.Description
    })
  }

  if (
    productDetails.CombinedBasePrice !== 0
      ? productDetails.CombinedBasePrice !== productDetails.CombinedSellPrice
      : false
  ) {
    productDetails.costBreakDownData = [
      {
        name: messages[language].regularLabel,
        value: productDetails.CombinedBasePrice,
      },
      {
        name: messages[language].saleLabel,
        value: productDetails.CombinedSellPrice,
      },
      {
        name: messages[language].saveLabel,
        value:
          productDetails.CombinedBasePrice - productDetails.CombinedSellPrice,
      },
    ]
  }
  if (
    productDetails.FeetoDisplay &&
    !isEmptyArray(productDetails.FeetoDisplay)
  ) {
    productDetails.FeetoDisplay = getFeeToDisplay(
      productDetails.FeetoDisplay,
      language
    )
  }
  const shoppingListData = []
  if (productDetails?.EditableShoppingLists?.length) {
    productDetails.EditableShoppingLists.forEach((data) => {
      shoppingListData.push({
        name: data.Name,
        value: data.ShoppingListId,
      })
    })
  }
  productDetails.EditableShoppingLists = shoppingListData
  productDetails.IsPromotional = false
  if (
    productDetails?.PromoInfo?.length &&
    productDetails?.PromoInfo?.ContainPromo
  ) {
    productDetails.IsPromotional = productDetails.PromoInfo.ContainPromo
  }

  const finalProductDetails = addReviewRatingTab(
    productDetails,
    productReviewsCanRead,
    productReviewsCanWrite,
    language,
    productDetails?.ProductReview?.ReviewCount
  )
  return finalProductDetails
}
const addReviewRatingTab = (
  productDetails,
  productReviewsCanRead,
  productReviewsCanWrite,
  language,
  reviewCount
) => {
  const reviewTab = {
    Name: messages[language].reviews,
    Sections: [
      {
        SH: '8',
        Type: 'Reviews',
        Name: 'Reviews',
        SectionType: 8,
        ShowHeader: true,
        Attributes: [],
      },
    ],
  }
  if (
    (reviewCount > REVIEW_COUNT_ZERO && productReviewsCanRead) ||
    productReviewsCanWrite
  ) {
    productDetails?.ProductDetailInformation?.Tabs?.push(reviewTab)
  }

  return productDetails
}
