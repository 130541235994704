export default {
  en: {
    initialValue: 'No file chosen',
    chooseFile: 'Choose File',
  },
  fr: {
    initialValue: 'Aucun fichier choisi',
    chooseFile: 'Choisir un fichier',
  },
}
