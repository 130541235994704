import PropTypes from 'prop-types'
import styles from './FileInput.module.scss'
import Button from 'components/shared/Button'
import Label from 'components/shared/Label'
import { useFileInputContext } from './FileInputContext'

export function FileInputUploadButton({
  text: textFromProps = '',
  'data-test': dataTest = '',
  disabled: disabledFromProps = false,
  isLoading: isLoadingFromProps = false,
  changedText = '',
  ...rest
}) {
  const {
    handleClick,
    handleKeyDown,
    disabled,
    isLoading,
    id,
    isFocus,
    fileInputText,
    isInitialValue,
  } = useFileInputContext()

  const text = textFromProps || fileInputText
  const finalDataTest = dataTest || `${id}-file-uploader-button`
  const finalDisabled = disabledFromProps || disabled
  const finalIsLoading = isLoadingFromProps || isLoading
  const finalText = (!isInitialValue ? changedText : text) || text

  return (
    <Label htmlFor={id} className={styles.content}>
      <Button
        role="button"
        focus={isFocus}
        whiteSpace="nowrap"
        aria-controls={`${id}-filename`}
        text={finalText}
        data-test={finalDataTest}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        disabled={finalDisabled}
        isLoading={finalIsLoading}
        {...rest}
      />
    </Label>
  )
}

FileInputUploadButton.propTypes = {
  text: PropTypes.string,
  'data-test': PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  changedText: PropTypes.string,
  borderRadiusRightType: PropTypes.string,
}
