import classNames from 'classnames'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Icon from 'shared/Icon'
import { isProduction } from '~/config/env/environmentUtils'
import { propsToOmit } from '../FormControlConstants'
import styles from './Checkbox.module.scss'

/**
 * Name: Checkbox
 * Desc: Render checkbox
 * @param {string} id
 * @param {string or object} label
 */

const Checkbox = forwardRef(
  (
    {
      labelSelectNone,
      variant,
      space,
      notAllowed = false,
      dataTest,
      'data-test': dataTestProp = '',
      labelDataTest = 'checkbox',
      labelDataTestDeeper = '',
      ...rest
    },
    ref
  ) => {
    const finalDataTest = dataTestProp || dataTest
    const finalLabelDataTest = labelDataTestDeeper || labelDataTest
    const labelSelectNoneVal = labelSelectNone ? 1 : 0
    const checkboxClasses = classNames(styles.checkBox, {
      [styles.m0]: space === 'm0',
      [styles.notAllowedCursor]: notAllowed,
      [styles.categoryList]: space === 'categoryList',
    })
    const restProps = omit(rest, propsToOmit)
    const commonProps = {
      type: 'checkbox',
      'aria-label': restProps.name,
      id: restProps.id,
      'data-test': finalDataTest,
      ref,
    }

    const { className, ...restPropsWithoutClass } = restProps
    return (
      <div className={checkboxClasses}>
        {variant === 'star' ? (
          <>
            <input {...commonProps} {...restPropsWithoutClass} />
            <Icon
              variant={
                restPropsWithoutClass.checked ? 'rating' : 'ratingInactive'
              }
              className={className}
              color="red"
            />
          </>
        ) : (
          <>
            <input {...commonProps} {...restProps} />
            <label
              data-label-select-none={labelSelectNoneVal}
              data-test={finalLabelDataTest}
              data-input="checkbox"
              htmlFor={rest.id}
            >
              {rest.label}
            </label>
          </>
        )}
      </div>
    )
  }
)

if (!isProduction()) {
  Checkbox.displayName = 'Checkbox'
}

// Props validation
Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  space: PropTypes.string,
  notAllowed: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  labelDataTest: PropTypes.string,
  labelDataTestDeeper: PropTypes.string,
  'data-test': PropTypes.string,
  labelSelectNone: PropTypes.bool,
  dataTest: PropTypes.string,
}

export default Checkbox
