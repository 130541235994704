import {
  TOGGLE_LANGUAGE,
  UPDATE_CART,
  SWITCH_PWGS,
  SET_SESSION_VALUE,
} from './constants'

export const changeLanguage = (language) => {
  return { type: TOGGLE_LANGUAGE, language }
}

export const setSessionValue = (key, value) => {
  const payload = { key, value }
  return { type: SET_SESSION_VALUE, payload }
}

export const switchPwgs = () => {
  return { type: SWITCH_PWGS }
}

export function refreshCart(timestamp) {
  const value = !timestamp ? new Date().getTime() : timestamp
  const payload = { key: 'cartUpdateTimeStamp', value }
  return { type: UPDATE_CART, payload }
}
